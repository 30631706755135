import './styles/app.scss';
import './styles/css-fadeshow.min.css';

// start the Stimulus application
import * as bootstrap from 'bootstrap';

import 'jquery'

import AOS from 'aos';
import 'aos/dist/aos.css'; 

import './js/carousel';
import './js/jquery.waypoints.min';
import './js/jquery.animateNumber.min';
import './js/isotope.js'

const $ = require('jquery');

AOS.init(
  {
    disable: function() {
      var maxWidth = 997;
      return window.innerWidth < maxWidth;
    },
    startEvent: 'load',
  }
);

$(window).on('load', function() {
  AOS.refresh();
});

$(function () {
  setTimeout(function () {
    $(".overlay").fadeOut( "slow", function() {
      // Animation complete.
    });
  }, 2500);
});

(function($) {
    var counter = function() {
        $('.aboutus-counter').waypoint( function( direction ) {
            if( direction === 'down' && !$(this.element).hasClass('ftco-animated') ) {
                var comma_separator_number_step = $.animateNumber.numberStepFactories.separator(',')
                $('.number').each(function(){
                    var $this = $(this),
                    num = $this.data('number');
                    $this.animateNumber(
                    {
                        number: num.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}),
                        numberStep: comma_separator_number_step
                    }, 2000
                    );
                });
            }
        } , { offset: '95%' } );
    }
    counter();
})(jQuery);

var cards = document.getElementsByClassName('card'),
    transforms = [
  { x: 0,
    y: 0,
    z: 0,
    scale: 1,
    opacity: 1
  },
  { x: '-35%',
    y: '20%',
    z: '-50px',
    scale: 0.6,
    opacity: 0.8
  },
  {
    x: '35%',
    y: '20%',
    z: '-50px',
    scale: 0.6,
    opacity: 0.8
  }
];

var nextTransform = function(x) {
  if (x >= cards.length - 1) {
    x = 0;
  } else {
    x++;
  }
  return x;
};

function next() {
  for (var i = 0; i < cards.length; i++) {
    cards[i].style.transform = 
      'translateX(' + transforms[nextTransform(i)].x + ')' + 
      'translateY(' + transforms[nextTransform(i)].y + ')' + 
      'translateZ(' + transforms[nextTransform(i)].z + ')' +
      'scale(' + transforms[nextTransform(i)].scale + ')';
    cards[i].style.opacity = transforms[nextTransform(i)].opacity;
  }
  transforms.push(transforms.shift());
}
  
var intervalId = window.setInterval(function(){
  next();
}, 5000);


var icon = document.getElementById("icon");
var icon1 = document.getElementById("a");
var icon2 = document.getElementById("b");
var icon3 = document.getElementById("c");
var nav = document.getElementById('nav');
var blue = document.getElementById("blue");

icon.addEventListener('click', function() {
  icon1.classList.toggle('a');
  icon2.classList.toggle('c');
  icon3.classList.toggle('b');
  nav.classList.toggle('show');
  blue.classList.toggle('slide');
});


if ($('.carousel').length) {
  let prevBtn = document.getElementById('prev');
  let nextBtn = document.getElementById('next');
  let carousel = document.querySelector('.carousel');
  let items = carousel.querySelectorAll('.list .item');
  let indicator = carousel.querySelector('.indicators');
  let dots = indicator.querySelectorAll('.indicators ul li');
  
  let active = 0;
  let firstPosition = 0;
  let lastPosition = items.length - 1;
  let autoPlay;


  const startAutoPlay = () => {
      clearInterval(autoPlay); 
      autoPlay = setInterval(() => {
          nextBtn.click();
      }, 7500);
  }
  startAutoPlay();

  const setSlider = () => {
      let itemActiveOld = carousel.querySelector('.list .item.active');
      if(itemActiveOld) itemActiveOld.classList.remove('active');
      items[active].classList.add('active');

      let dotActiveOld = indicator.querySelector('.indicators ul li.active');
      if(dotActiveOld) dotActiveOld.classList.remove('active');
      dots[active].classList.add('active');

      indicator.querySelector('.number').innerText = '0' + (active + 1);
      startAutoPlay();
  }
  setSlider();

  nextBtn.onclick = () => {
      active = active + 1 > lastPosition ? 0 : active + 1;
      carousel.style.setProperty('--calculation', 1);
      setSlider();
  }
  prev.onclick = () => {
      active = active - 1 < firstPosition ? lastPosition : active - 1;
      carousel.style.setProperty('--calculation', -1);
      setSlider();
      clearInterval(autoPlay);
      autoPlay = setInterval(() => {
          nextBtn.click();
      }, 7500);
  }
  dots.forEach((item, position) => {
      item.onclick = () => {
          active = position;
          setSlider();
      }
  })

}